import { convertToDate } from "../utils/dates";

import { Badge } from "@bphxd/ds-core-react";
import { Col } from "reactstrap";
import { AskAxelButton } from "../components/button";
import { Link } from "../components/Link";
import { LinkButton } from "../components/link-button";
import { PageHeader } from "../components/page-header";
import { PageSection, PageWithNoPadding } from "../components/page-template";
import { Fragment } from "react";

export type WhatsNewCardData = {
  date?: string;
  headline: string;
  body?: string;
  imgSrc: string;
  previewCardImgSrc: string;
  bgColor?: string;
  learnMoreLink?: {
    href: string;
    text: string;
  };
  ctaButton?: {
    href?: string;
    text: string;
    axelPrompt?: string;
    axelChatName?: string;
  };
};

export const WHATS_NEW_CARDS_DATA: WhatsNewCardData[] = [
  {
    headline: "Do you struggle to find common code solutions across bp?",
    body: "When you have two lines of body copy then we can allow for three lines of description copy. This is because we have lots of space. We’ll need to truncate the description if it goes over …",
    imgSrc: "/images/png/code-example-4-3.png",
    previewCardImgSrc: "/images/png/code-example-16-9.png",
    ctaButton: {
      text: "Search in Axel",
      axelPrompt: "Can you show me a code example of how to emit telemetry from my FastApi app?",
      axelChatName: "Emitting telemetry from FastApi example",
    },
  },
  {
    headline: "Discover and execute common automations through Axel",
    body: "You can now find common Tooling automations in an Axel search and execute them as part of your dialog. See an example showing how to add a Grafana user to an organisation.",
    imgSrc: "/images/png/link-ado-image-4-3.png",
    previewCardImgSrc: "/images/png/ask-axel-16-9-nobg.png",
    bgColor: "#ece5f6",
    ctaButton: {
      text: "Ask Axel a question",
      axelPrompt: "What is Axel? Include some code snippets and introduce Axel with any relevant media.",
      axelChatName: "Introducing Axel",
    },
  },
  {
    headline: "Supercharge your developers by using our AI-assistant, Axel",
    body: "Axel leverages data from various sources, including KnowledgeHub, SharePoint, Confluence, ADO Wikis, and Code Snippets, to provide you with comprehensive support and assistance in your day-to-day tasks.",
    imgSrc: "/images/png/axel-image-4-3.png",
    previewCardImgSrc: "",
    ctaButton: {
      text: "Ask Axel a question",
      axelPrompt: "What automations are available in the Automations Hub in Accelerate?",
      axelChatName: "Available automations in the Automations Hub",
    },
  },
  {
    headline: "Capture and document API endpoints in your source code and assess technologies in use",
    body: "Axel can discover and document REST and GraphQL based APIs that are defined in your source code. Associate your product and repositories with Accelerate and let Axel help generate your technical documentation for APIs and describe technologies in use.",
    imgSrc: "/images/png/product-metrics-image-4-3.png",
    previewCardImgSrc: "/images/png/ask-axel-16-9.png",
    learnMoreLink: {
      href: "/knowledgehub/EBHNzp",
      text: "Learn more",
    },
  },
];

export function WhatsNewPreviewCard(props: WhatsNewCardData) {
  const { date, headline, previewCardImgSrc, ctaButton, learnMoreLink, bgColor } = props;

  return (
    <div className="d-flex flex-column justify-content-between rounded-6 bg-white">
      <div>
        <img
          src={previewCardImgSrc}
          alt="what's new preview"
          className="img-fluid rounded-top-6"
          style={{ backgroundColor: bgColor ?? "" }}
        />
        <div className="p-6 pb-2">
          {date && (
            <Badge color="light" className="mb-4">
              {convertToDate(date)}
            </Badge>
          )}
          <p className="fw-light mb-0">{headline}</p>
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap p-6 pt-0">
        {learnMoreLink?.href && (
          <Link href={learnMoreLink.href} name="whats_new_learn_more" className="small me-4">
            {learnMoreLink.text}
          </Link>
        )}
        {ctaButton?.href && (
          <div className="me-4">
            <LinkButton
              href={ctaButton.href}
              size="xs"
              level="tertiary"
              theme="standard"
              rounded="pill"
              name="whats_new_cta"
            >
              {ctaButton.text}
            </LinkButton>
          </div>
        )}
        {ctaButton?.axelPrompt && (
          <div className="me-4">
            <AskAxelButton
              href={ctaButton.href}
              size="xs"
              level="tertiary"
              theme="standard"
              rounded="pill"
              name="whats_new_ask_axel"
              chatPrompt={ctaButton.axelPrompt}
              chatName={ctaButton.axelChatName}
            >
              {ctaButton.text}
            </AskAxelButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default function WhatsNewPage() {
  const breadcrumbs = [
    {
      text: "Home",
      href: `/`,
    },
    {
      text: "What’s new",
    },
  ];
  return (
    <PageWithNoPadding name="what’s new" title="what’s new" backgroundClassName="bg-secondary">
      <div className="bg-white pb-9">
        <PageSection>
          <PageHeader pageTitle="What’s new in Accelerate" breadcrumbs={breadcrumbs} />
          <Col xs="6">
            <p className="lead fw-light lh-1-5 mb-0">
              Keep up with Accelerates latest product news, new releases and updates as they go live.
            </p>
          </Col>
        </PageSection>
      </div>
      <div className="bg-secondary pt-9">
        <PageSection>
          {WHATS_NEW_CARDS_DATA.map((data, index) => {
            const { imgSrc, headline, body, learnMoreLink, ctaButton } = data;
            return (
              <Fragment key={index}>
                <div className="d-flex">
                  <div className="d-flex flex-column bg-white p-8 w-50 rounded-start-6 justify-content-center">
                    <h3 className="mb-4 h3">{headline}</h3>
                    <p className="fw-light mb-6">{body}</p>
                    <div className="d-flex align-items-center flex-wrap">
                      {learnMoreLink?.href && (
                        <Link href={learnMoreLink.href} name="whats_new_full_card__learn_more" className="small me-4">
                          {learnMoreLink.text}
                        </Link>
                      )}
                      {ctaButton?.href && (
                        <div className="me-4">
                          <LinkButton
                            href={ctaButton.href}
                            size="xs"
                            level="tertiary"
                            theme="standard"
                            rounded="pill"
                            name="whats_new_full_card_cta"
                          >
                            {ctaButton.text}
                          </LinkButton>
                        </div>
                      )}
                      {ctaButton?.axelPrompt && (
                        <div className="me-4">
                          <AskAxelButton
                            href={ctaButton.href}
                            size="xs"
                            level="tertiary"
                            theme="standard"
                            rounded="pill"
                            name="whats_new_full_card__ask_axel"
                            chatPrompt={ctaButton.axelPrompt}
                            chatName={ctaButton.axelChatName}
                          >
                            {ctaButton.text}
                          </AskAxelButton>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-50">
                    <img src={imgSrc} alt="what's new image" className="img-fluid rounded-end-6" />
                  </div>
                </div>
                {index + 1 < WHATS_NEW_CARDS_DATA.length && (
                  <div style={{ height: 32 }} className="ms-8 border-start border-1 border-dark-subtle" />
                )}
              </Fragment>
            );
          })}
        </PageSection>
      </div>
    </PageWithNoPadding>
  );
}
